.product,
.farmer {
  /* position: absolute; */
  /* left: 25%; */
  /* padding: 10px; */
  border-radius: 10px;
  /* font-size: 2px; */
  align-items: center;
  /* background-color: #dbf2ae; */
  background-color: black;
  color: #fff;
}
.farmerLabel {
  /* position: absolute; */
  /* padding: 10px; */
  width: auto;
  border-radius: 5px;
  /* font-size: 2px; */
  align-items: center;
  /* background-color: #dbf2ae; */
  background-color: rgba(252, 85, 48, 0.8);
  color: #fff;
}
.product .avatar {
  background-color: rgba(0, 0, 0, 0.1);
}
.farmer {
  min-width: 250px;
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25));
  /* margin-left: 50px; */
}
.markerPin {
  display: flex;
  position: fixed;
  /* min-width: 100%; */
  top: 35% !important;
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
}
.img {
  /* width: 70px; */
  margin: 0 auto;
}
.avatar {
  background-color: rgba(255, 255, 255, 0.65);
  border-radius: 50%;
}
.content {
  margin: 10px;
  font-size: 11px;
  position: relative;
  text-align: center;
}
.content1 {
  margin: 10px;
  font-size: 11px;
  position: relative;
  text-align: left;
  width: 225px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tipContainer::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -30px;
  border-radius: 100%;
  border: 30px solid rgba(219, 111, 42, 0.2);
}
.content::before {
  content: "";
  position: absolute;
  top: calc(100% + 34px);
  left: 50%;
  margin-left: -6px;
  border: 6px solid rgba(219, 111, 42, 1);
  border-radius: 100%;
}
.content::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -12px;
  border-width: 28px;
  border-left-width: 12px;
  border-right-width: 12px;
  border-bottom: none;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.title {
  font-size: 14px;
}
.radar-marker {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
}
