body {
  /* font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif; */
  font-family: "Montserrat";
}
.bold {
  font-weight: 600;
}
.flex {
  display: flex;
}

.flex-stretch {
  display: flex;
}

.flex-stretch > div {
  flex-grow: 1;
}

.flex-grow {
  flex-grow: 1;
}

.bg-grey {
  background-color: rgb(245 245 245);
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

.space-bw {
  justify-content: space-between;
}
.space-evenly {
  justify-content: space-evenly;
}
.space-around {
  justify-content: space-around;
}

.middle {
  align-items: center;
}

.stretch {
  flex-grow: 1;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

a {
  text-decoration: none;
}

td.fixed-width-column {
  width: 100px;
}
.add {
  margin-right: 20px;
  margin-top: -55px;
}

.p-10 {
  padding: 10px;
}
.p-4 {
  padding: 4px;
}
.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.m-10 {
  margin: 10px;
}
.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.badge {
  background-color: blueviolet;
  color: white;
  padding: 4px 10px 4px 10px;
}

.nav-link {
  margin-left: 10px;
}

.link-text {
  color: #067ead;
  cursor: pointer;
}

.link-text:hover {
  text-decoration: underline;
}

.csv-reader-input {
  padding: 10px 5px;
}
.file-input,
.csv-input {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

label.file,
label.csv-input-label {
  display: flex;
  font-size: 16px;
  border: 1px solid #2e7d32;
  padding: 5px 12px;
  border-radius: 5px;
  cursor: pointer;
  color: #2e7d32;
}

label.file .icon,
label.csv-input-label .icon {
  padding: 3px;
  /* width: 50px;
    height: 50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
label.file,
label.file:hover,
label.csv-input-label:hover,
input[type="file"]:focus + label.file {
  background: #dbf5dc;
}

label.file {
  display: inline-block;
}

label.required::after {
  content: " *";
  color: red;
}

#report-form .MuiGrid-item {
  margin-top: 10px;
  padding: 20px;
}

.column {
  flex-direction: column;
}

.logo-nav {
  display: flex;
  margin: 4px;
  width: 20%;
  max-width: 60px;
}

.css-692fgp,
.css-vcfpfv,
.css-1b3lipl:before {
  background-color: #277048;
}

::-webkit-scrollbar {
  width: 10px; /* for vertical scrollbars */
  height: 10px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.01);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  border: 1px solid white;
}

a {
  color: #067ead;
}

a:hover {
  text-decoration: underline;
}

.hover-highlight:hover {
  background: rgba(0, 0, 0, 0.04);
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.rdt_Table .rdt_TableHeadRow div {
  font-weight: 800;
}

.color-error {
  color: "red";
}

.bg-gradient-0 {
  background-repeat: no-repeat;
  background: rgba(162, 208, 171, 1); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    0deg,
    rgba(253, 170, 101, 1) 0%,
    rgba(253, 156, 75, 1) 33%,
    rgba(252, 142, 50, 1) 66%,
    rgb(252, 128, 25) 100%
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    0deg,
    rgba(253, 170, 101, 1) 0%,
    rgba(253, 156, 75, 1) 33%,
    rgba(252, 142, 50, 1) 66%,
    rgba(252, 128, 25, 1) 100%
  );
}

.bg-gradient-1 {
  background: #d9a7c7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #fffcdc,
    #d9a7c7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #fffcdc,
    #d9a7c7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bg-gradient-grey {
  background: #909090; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #c5c5c5,
    #8b8b8b
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #c5c5c5,
    #8b8b8b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.blurred-backdrop,
.map-marker {
  /* position: fixed; */
  /* inset: 0px; */
  z-index: 100;
  background-image: linear-gradient(
    125deg,
    rgba(134, 121, 249, 0.05),
    rgba(113, 255, 151, 0.05)
  );
  /* animation-name: fadeIn; */
  animation-duration: 200ms;
  outline: none;
  background-color: rgba(8, 17, 24, 0.96);

  background-color: initial;
  backdrop-filter: blur(50px);
  box-shadow: rgb(0 0 0 / 50%) 0px 2px 15px 0px;
}

.map-marker {
  background-color: rgba(8, 17, 24, 0.6);
  color: white;
  border-radius: 6px;
  backdrop-filter: blur(100px);
}

.map-marker .marker-detail {
  display: none;
  width: 0;
}

.map-marker:hover .marker-detail {
  transition: width 1s;
  display: block;
  width: auto;
}

.shadow {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

.date-field label {
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
}

.currency::before {
  content: "₹ ";
}

.currency {
  /* font-size: 16px; */
  font-weight: bold;
}

.blue-dot,
.red-dot {
  margin-left: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.blue-dot {
  background-color: #0a95cc;
}

.red-dot {
  background-color: #cc310a;
}

.metabase-iframe-container iframe {
  width: 100%;
  height: calc(100vh - 80px);
}
/* .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    position: relative;
    
  } */

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.qwerty .MuiPaper-root {
  height: fit-content !important;
  margin: auto;
  overflow: initial !important;
}
